import React from 'react';

const AuthContext = React.createContext();

class AuthProvider extends React.Component {
  state = {
    isAuth: false,
    token: null,
  };

  UNSAFE_componentWillMount() {
    const token = localStorage.getItem('token');
    if (token) {
      this.setState({
        isAuth: true,
        token,
      });
    }
  }

  login = () => {
    const clientId = process.env.REACT_APP_CONTENTFUL_CLIENT_ID;
    const redirectUrl = process.env.REACT_APP_CONTENTFUL_REDIRECT_URL;
    const scope = process.env.REACT_APP_CONTENTFUL_SCOPE;

    window.location = `https://be.contentful.com/oauth/authorize?response_type=token&client_id=${clientId}&redirect_uri=${redirectUrl}&scope=${scope}`;
  }

  logout = () => {
    localStorage.removeItem('token');
    this.setState({
      isAuth: false,
      token: null,
    });
  }

  setAccessToken = (token, callback) => {
    localStorage.setItem('token', token);
    this.setState({
      isAuth: true,
      token
     });

     if (callback) {
       callback();
     }
  }

  render() {
    return (
      <AuthContext.Provider
        value={{
          isAuth: this.state.isAuth,
          token: this.state.token,
          login: this.login,
          logout: this.logout,
          setAccessToken: this.setAccessToken,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer, AuthContext };
