export const formConfig = {
  modesOfTransport: {
    label: 'Valitse liikennemuoto',
    name: 'modesOfTransport',
    items: [
      { value: 'PUBLIC_TRANSPORT', name: 'Julkinen liikenne' },
      { value: 'CAR', name: 'Auto' },
      { value: 'BICYCLE', name: 'Pyöräily' },
      { value: 'PEDESTRIAN', name: 'Kävely' },
    ],
  },
  mainClass: {
    label: 'Valitse pääluokka',
    name: 'class',
    subproperty: 'class',
    items: [
      { value: '', name: 'Valitse pääluokka', disabled: true },
      { value: 'SHZ', name: 'Ajo-olot liukkaus kelitilanne' },
      { value: 'OHZ', name: 'Este esine tai eläin tiellä' },
      { value: 'KRK', name: 'Kelirikko' },
      { value: 'RES', name: 'Liikennerajoitukset' },
      { value: 'LOS', name: 'Liikennetilanne' },
      { value: 'FOS', name: 'Näkyvyys- ja ympäristöolosuhteet' },
      { value: 'ACC', name: 'Onnettomuus' },
      { value: 'EQU', name: 'Tienvarsilaitteiden toiminta' },
      { value: 'RMT', name: 'Tietyö' },
      { value: 'MHZ', name: 'Vaaraa aiheuttava ajoneuvo tai kuljetus tiellä' },
      { value: 'ACT', name: 'Yleisö- tai muut tapahtumat' },
    ],
  },
  subClass: {
    label: 'Valitse pääfraasi',
    name: 'class',
    subproperty: 'subclass',
    items: [
      { value:'', name: 'Valitse pääfraasi', disabled: true },
    ],
    SHZ: [
      { value:'AQD', name: 'Vesiliirron vaara' },
      { value:'DCD', name: 'Huono ajokeli' },
      { value:'DCN', name: 'Ajo-olot parantuneet' },
      { value:'FUE', name: 'Öljyä tiellä' },
      { value:'IBU', name: 'Tiet jäätymässä' },
      { value:'ICP', name: 'Paikoin liukasta' },
      { value:'JJM', name: 'Kevyen liikenteen väylät jäisiä ja märkiä' },
      { value:'JJT', name: 'Kevyen liikenteen väylät jäätymässä' },
      { value:'JLU', name: 'Kevyen liikenteen väylillä lunta' },
      { value:'JPL', name: 'Kevyen liikenteen väylät paikoin liukkaita' },
      { value:'KUO', name: 'Kuoppia tiessä' },
      { value:'OIL', name: 'Polttoainetta tiellä' },
      { value:'PAV', name: 'Päällystevaurio' },
      { value:'PKI', name: 'POLIISIN KELI-ILMOITUS' },
      { value:'RBI', name: 'Mustaa jäätä' },
      { value:'RPC', name: 'Tienpinta huonossa kunnossa' },
      { value:'RSL', name: 'Tiet ovat liukkaita' },
      { value:'RWI', name: 'Tiet jäisiä ja märkiä' },
      { value:'SAV', name: 'Savea tiellä' },
      { value:'SLU', name: 'Lumisohjoa' },
      { value:'SNP', name: 'Lumi- tai jääpolanne' },
      { value:'SNR', name: 'Lumikinoksia' },
      { value:'SPK', name: 'Sirotepaikkaus, irtokiviä' },
      { value:'SRO', name: 'Lunta tiellä' },
      { value:'STD', name: 'Myrskyvaurioita' },
      { value:'STK', name: 'Soratien kunto huono' },
      { value:'TOL', name: 'Tie on liukas' },
      { value:'VET', name: 'Vettä tiellä' },
      { value:'RIL', name: 'Risteykset liukkaita' },
      { value:'RIC', name: 'Tiet ovat jäisiä' },
      { value:'JLI', name: 'Kevyen liikenteen väylät liukkaita' },
    ],
    OHZ: [
      { value:'ANH', name: 'Poroja tiellä' },
      { value:'ANL', name: 'Hirviä tiellä' },
      { value:'ANM', name: 'Eläimiä tiellä' },
      { value:'FIG', name: 'Maastopalo' },
      { value:'FIR', name: 'Tulipalo' },
      { value:'FLD', name: 'Tulva' },
      { value:'FLT', name: 'Puu kaatunut tielle' },
      { value:'FPC', name: 'Sähköjohtoja pudonnut tielle' },
      { value:'LSL', name: 'Tie sortunut' },
      { value:'OHX', name: 'Liikennettä vaarantava este tiellä' },
      { value:'SHL', name: 'Kuorma levinnyt tielle' },
    ],
    KRK: [
      { value:'KRI', name: 'Kelirikko' },
      { value:'TGW', name: 'Painorajoitus asetettu' },
    ],
    RES: [
      { value: 'LC1', name: 'Yksi ajokaista suljettu liikenteeltä' },
      { value: 'NLS', name: 'Ajokaistoja kavennettu' },
      { value: 'RCA', name: 'Toinen ajorata suljettu liikenteeltä' },
      { value: 'RCD', name: 'Tie suljettu liikenteeltä' },
      { value: 'RIN', name: 'Tie ajoittain suljettu liikenteeltä' },
      { value: 'RNL', name: 'Muuttuneet liikennejärjestelyt' },
      { value: 'SAT', name: 'Kohtaava liikenne, ajo vuorotellen ohjauksen mukaan' },
      { value: 'TLT', name: 'Tilapäinen liikennevalo-ohjaus' },
      { value: 'TAL', name: 'Akselipainorajoitus' },
      { value: 'TNR', name: 'Tilapäinen nopeusrajoitus' },
      { value: 'RET', name: 'Poikkeavat kaistajärjestelyt' },
      { value: 'LCS', name: 'Ajokaistoja on suljettu liikenteeltä' },
      { value: 'CTR', name: 'Liikenne ohjataan kaksisuuntaisena toiselle ajoradalle' },
      { value: 'TAV', name: 'Tie on avattu liikenteelle' },
      { value: 'BRC', name: 'Silta suljettu liikenteeltä' },
      { value: 'LC2', name: 'Kaksi ajokaistaa suljettu liikenteeltä' },
    ],
    LOS: [
      { value: 'LS1', name: 'Liikenne seisoo' },
      { value: 'LS2', name: 'Liikenne pysähtelee' },
      { value: 'LS3', name: 'Liikenne hidasta' },
      { value: 'LS4', name: 'Liikenne jonoutunut' },
      { value: 'LSR', name: 'Liikenne saattaa ruuhkautua' },
      { value: 'TBU', name: 'Liikenne ruuhkautuu' },
      { value: 'TCX', name: 'Liikenneruuhka ohi' },
      { value: 'LPA', name: 'Liikenne pysäytetään ajoittain' },
      { value: 'LS6', name: 'Pitkiä jonoja' },
      { value: 'TCN', name: 'Liikenneruuhka' },
      { value: 'DO', name: 'Kiertotie käytössä' },
      { value: 'LIN', name: 'Ajokaista ajoittain suljettu' },
      { value: 'LO1', name: 'Vain yksi ajokaista käytössä' },
      { value: 'LO2', name: 'Vain kaksi kaistaa käytössä' },
      { value: 'RAD', name: 'Kiertotieopastus' },
      { value: 'RBA', name: 'Liikenne pysäytetään' },
      { value: 'REB', name: 'Liittymisramppi tukossa' },
      { value: 'REC', name: 'Ramppi suljettu liikenteeltä' },
      { value: 'RXB', name: 'Poistumisramppi tukossa' },
      { value: 'TAA', name: 'Toinen ajokaista avattu liikenteelle' },
      { value: 'TUC', name: 'Tunneli suljettu liikenteeltä' },
      { value: 'YAK', name: 'Työkohteessa vain yksi ajokaista käytössä' },
      { value: 'TEA', name: 'Liikenne on palautumassa normaaliksi' },
      { value: 'LSO', name: 'Liikenne saattaa ruuhkautua' },
    ],
    FOS: [
      { value: 'BLS', name: 'Pöllyävää lunta' },
      { value: 'FOD', name: 'Sakeaa sumua' },
      { value: 'FOF', name: 'Jäätävää sumua' },
      { value: 'FOG', name: 'Sumua' },
      { value: 'FOP', name: 'Paikoitellen sumua' },
      { value: 'SMO', name: 'Savu haittaa näkyvyyttä' },
      { value: 'SPY', name: 'Roiskevesi haittaa näkyvyyttä' },
      { value: 'WIC', name: 'Kova sivutuuli' },
      { value: 'WIG', name: 'Puuskittainen tuuli' },
      { value: 'VIR', name: 'Näkyvyys heikentynyt' },
      { value: 'BLI', name: 'Lumimyrsky' },
      { value: 'GAL', name: 'Kova tuuli' },
      { value: 'RAF', name: 'Jäätävää sadetta' },
      { value: 'RAI', name: 'Vesisade' },
      { value: 'SFH', name: 'Sakea lumisade' },
      { value: 'SFL', name: 'Lumisade' },
      { value: 'SLT', name: 'Räntäsade' },
      { value: 'STM', name: 'Myrsky' },
      { value: 'HAI', name: 'Raekuuro' },
    ],
    ACC: [
      { value: 'ACB', name: 'Onnettomuus, jossa mukana linja-auto' },
      { value: 'ACH', name: 'Onnettomuus, jossa mukana raskas ajoneuvo' },
      { value: 'ACI', name: 'Onnettomuus' },
      { value: 'ACL', name: 'Onnettomuuspaikan raivaus käynnissä' },
      { value: 'ACM', name: 'Usean ajoneuvon onnettomuus' },
      { value: 'ACZ', name: 'Onnettomuus, jossa mukana vaarallisia aineita' },
      { value: 'AOL', name: 'Raskas ajoneuvo kaatunut tielle' },
      { value: 'ASP', name: 'Ulosajo' },
      { value: 'RNO', name: 'Rekan nosto' },
      { value: 'SON', name: 'SUURONNETTOMUUS' },
      { value: 'ENS', name: 'ENSITIEDOTE LIIKENNEONNETTOMUUDESTA' },
    ],
    EQU: [
      { value: 'SNW', name: 'Kaistaopasteet eivät toimi' },
      { value: 'TLO', name: 'Liikennevalot eivät toimi' },
      { value: 'VNW', name: 'Muuttuvat opasteet eivät toimi' },
      { value: 'HAV', name: 'Hirviaita vaurioitunut' },
      { value: 'KAV', name: 'Kaidevaurio' },
      { value: 'KKP', name: 'Kaivon kansi rikki/poissa paikaltaan' },
      { value: 'PYS', name: 'Pysäkkiin liittyvä ilmoitus' },
      { value: 'TVL', name: 'Tievalaistuksen lamppuja pimeänä' },
      { value: 'TVM', name: 'Tievalaistus vioittunut myrskyssä' },
      { value: 'TVO', name: 'Tievalaistus vioittunut onnettomuudessa' },
      { value: 'VKA', name: 'Järjestelmän vikatila' },
    ],
    RMT: [
      { value: 'ALI', name: 'Alikulkukäytävän rakentaminen' },
      { value: 'JYR', name: 'Päällysteen jyrsintätyö' },
      { value: 'KAI', name: 'Sadevesikaivojen puhdistustyö' },
      { value: 'KDT', name: 'Kaidetyö' },
      { value: 'KLV', name: 'Kevyenliikenteen väylän rakentaminen' },
      { value: 'LIJ', name: 'Liittymän parannustyö' },
      { value: 'LOH', name: 'Tienvarsilaitteiden huoltotyö' },
      { value: 'NIT', name: 'Niittotyö' },
      { value: 'RBL', name: 'Räjäytystyö' },
      { value: 'RBM', name: 'Siltatyö' },
      { value: 'RCW', name: 'Tienrakennustyö' },
      { value: 'REW', name: 'Raivaus- ja korjaustöitä' },
      { value: 'RMK', name: 'Kunnossapito- ja hoitotyöt' },
      { value: 'RMV', name: 'Työmaa-ajoa liikenteen seassa' },
      { value: 'RMW', name: 'Tiemerkintätyö' },
      { value: 'RRW', name: 'Tietyö keskikaistalla' },
      { value: 'RWK', name: 'Tietyö' },
      { value: 'RWL', name: 'Pitkäaikainen tienparannustyö' },
      { value: 'RWM', name: 'Tienparannustyö' },
      { value: 'RWR', name: 'Päällystystyö' },
      { value: 'SJT', name: 'Sähköjohtotyö' },
      { value: 'SOK', name: 'Sivuojan kaivuutyö' },
      { value: 'TMT', name: 'Tutkimus- ja mittaustyö' },
      { value: 'TRI', name: 'Tasoristeystyö' },
      { value: 'TVT', name: 'Tievalaistustyö' },
      { value: 'WBC', name: 'Kaapelityö' },
      { value: 'WBS', name: 'Kunnallistekninen työ' },
      { value: 'VMT', name: 'Viimeistelytyö' },
      { value: 'VRT', name: 'Vesakon raivaus' },
      { value: 'LVT', name: 'Liikennevalotyö' },
    ],
    MHZ: [
      { value: 'ABL', name: 'Erikoiskuljetus' },
      { value: 'BKD', name: 'Rikkoutunut ajoneuvo tiellä' },
      { value: 'HBD', name: 'Rikkoutunut raskas ajoneuvo tiellä' },
      { value: 'MIL', name: 'Sotilassaattue' },
      { value: 'RAJ', name: 'Raskas ajoneuvo jumissa' },
      { value: 'SMV', name: 'Nestettä vuotanut liikkuvasta ajoneuvosta' },
      { value: 'VFR', name: 'Palava ajoneuvo' },
      { value: 'VWC', name: 'Ajoneuvo väärällä ajoradalla' },
    ],
    ACT: [
      { value: 'EFA', name: 'Messut' },
      { value: 'EPR', name: 'Kulkue' },
      { value: 'ESP', name: 'Urheilutapahtuma' },
      { value: 'EVD', name: 'Mielenosoitus' },
      { value: 'EVM', name: 'Yleisötapahtuma' },
      { value: 'MAR', name: 'Maratonjuoksu' },
      { value: 'PEL', name: 'Pelastusharjoitus' },
    ],
  },
  title: {
    fi: {
      label: 'Otsikko (FI)',
      name: 'title',
      subproperty: 'fi',
    },
    sv: {
      label: 'Otsikko (SV)',
      name: 'title',
      subproperty: 'sv',
    },
    en: {
      label: 'Otsikko (EN)',
      name: 'title',
      subproperty: 'en',
    },
  },
  startTime: {
    label: 'Alkuaika',
    name: 'startTime',
  },
  endTime: {
    label: 'Päättymisaika',
    name: 'endTime',
  },
  description: {
    fi: {
      label: 'Kuvaus (FI)',
      name: 'description',
      subproperty: 'fi',
    },
    sv: {
      label: 'Kuvaus (SV)',
      name: 'description',
      subproperty: 'sv',
    },
    en: {
      label: 'Kuvaus (EN)',
      name: 'description',
      subproperty: 'en',
    },
  },
  images: {
    label: 'Lataa tiedotteeseen liittyviä kuvia (Kuvan maksimikoko 1Mb)',
    name: 'images',
  },
  url: {
    label: 'Tiedotteeseen liittyvä http-linkki',
    name: 'url',
    placeholder: 'http://www.osoite.fi',
  },
  geojson: {
    label: 'Valitse sijainti kartalta',
    name: 'geojson',
  },
  trafficDirection: {
    label: 'Vaikutussuunta',
    name: 'trafficDirection',
    items: [
      { value: '', name: 'Valitse vaikutussuunta', disabled: true },
      { value: 'BOTH', name: 'Molempiin suuntiin' },
      { value: 'POSITIVE', name: 'Valittuun suuntaan' },
      { value: 'NEGATIVE', name: 'Vastakkaiseen suuntaan' },
    ],
  },
  trafficDirectionFreeText: {
    fi: {
      label: 'Vaikutussuunta (FI)',
      name: 'trafficDirectionFreeText',
      subproperty: 'fi',
    },
    sv: {
      label: 'Vaikutussuunta (SV)',
      name: 'trafficDirectionFreeText',
      subproperty: 'sv',
    },
    en: {
      label: 'Vaikutussuunta (EN)',
      name: 'trafficDirectionFreeText',
      subproperty: 'en',
    },
  },
  severity: {
    label: 'Haitta-aste',
    name: 'severity',
    items: [
      { value: '', name: 'Valitse haitta-aste', disabled: true },
      { value: 'HIGH', name: 'Merkittävää haittaa liikenteelle' },
      { value: 'MEDIUM', name: 'Haittaa liikenteelle' },
      { value: 'LOW', name: 'Ei haittaa liikenteelle' },
    ],
  },
  duration: {
    label: 'Arvioitu kesto',
    name: 'duration',
    items: [
      { value: '', name: 'Valitse arvioitu kesto' },
      { value: 'LTE_30', name: 'Arvioitu kesto alle 30 minuuttia' },
      { value: 'GTE_30_LTE_60', name: 'Arvioitu kesto 30 - 60 minuuttia' },
      { value: 'GTE_60_LTE_180', name: 'Arvioitu kesto 1 - 3 tuntia' },
      { value: 'GTE_180_LTE_360', name: 'Arvioitu kesto 3 - 6 tuntia' },
      { value: 'GTE_360', name: 'Arvioitu kesto yli 6 tuntia' },
    ],
  },
  temporarySpeedLimit: {
    label: 'Nopeusrajoitus',
    name: 'temporarySpeedLimit',
    items: [
      { value: '', name: 'Valitse nopeusrajoitus' },
      { value: 30, name: '30 km/h' },
      { value: 40, name: '40 km/h' },
      { value: 50, name: '50 km/h' },
      { value: 60, name: '60 km/h' },
      { value: 70, name: '70 km/h' },
      { value: 80, name: '80 km/h' },
      { value: 100, name: '100 km/h' },
    ],
  },
  additionalInfo: {
    label: 'Lisämääre',
    name: 'additionalInfo',
    items: [
      { value: '', name: 'Valitse lisämääre' },
      { value: 'AT', name: 'Avotulella kuumennus' },
      { value: 'FD', name: 'Seuraa kiertotieopastusta' },
      { value: 'PD', name: 'Poliisi ohjaa liikennettä' },
    ],
  },
  detourEnabled: {
    label: 'Kiertotie (Kyllä/Ei)',
    name: 'detourEnabled',
  },
  oversizeLoad: {
    label: 'Erikoiskuljetus',
    name: 'oversizeLoad',
    placeholder: 'Muodossa (Lev. x Kork. x Pit.) metreissä'
  },
  vehicleSizeLimit: {
    label: 'Ajoneuvorajoitus',
    name: 'vehicleSizeLimit',
    placeholder: 'Muodossa (Lev. x Kork. x Pit. x Paino) metreissä ja tonneissa'
  },
};
