import React from 'react';

const Home = () => {
  return (
    <div className="home">
      <h1>Tervetuloa</h1>
      <p>Tämä on Oulun kaupungin liikennepäivystäjän tiedotetyökalu.</p>
    </div>
  );
}

export default Home;
