import { gql } from '@apollo/client';

export const GET_LIST = gql`
  query FetchTrafficAnnouncements($page: Int) {
    trafficAnnouncements(page: $page) {
      announcementId
      publishedVersion
      modesOfTransport
      title {
        fi
      }
      startTime
      endTime
      geojson
      severity
    }
  }
`;

export const GET_ITEM = gql`
  query FetchTrafficAnnouncement($id: ID!) {
    trafficAnnouncement(id: $id) {
      announcementId
      publishedVersion
      modesOfTransport
      class {
        class
        subclass
      }
      title {
        fi
        sv
        en
      }
      startTime
      endTime
      description {
        fi
        sv
        en
      }
      images {
        id
        imageId
        contentType
        fileName
        url
      }
      url
      geojson
      trafficDirectionFreeText {
        fi
        sv
        en
      }
      duration
      temporarySpeedLimit
      additionalInfo
      severity
      detour
      oversizeLoad
      vehicleSizeLimit
    }
  }
`;
