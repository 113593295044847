import React from 'react';
import { Link } from 'react-router-dom';

import { AuthConsumer } from './AuthContext'
import Logo from './logo.png'
import './Header.scss';

const Header = () => (
  <AuthConsumer>
    {({ isAuth, login, logout }) => (
      <div className="navbar">
        <ul>
          <li className="logo">
            <Link to="/">
              <img src={Logo} alt="Logo" />
            </Link>
          </li>
          {isAuth && ([
            <li key="create">
              <Link to="/create">Uusi tiedote</Link>
            </li>,
            <li key="list">
              <Link to="/list">Tiedotelista</Link>
            </li>
          ])}
          <li>
            {isAuth ? (
              <button onClick={logout}>Kirjaudu ulos</button>
            ) : (
              <button onClick={login}>Kirjaudu sisään</button>
            )}
          </li>
        </ul>
      </div>
    )}
  </AuthConsumer>
);

export default Header;
