import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import React, { useEffect } from 'react';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import "react-leaflet-fullscreen/styles.css"
import "leaflet-geosearch/assets/css/leaflet.css"
import { FullscreenControl } from 'react-leaflet-fullscreen';
import MapDrawControls from './MapDrawControls';

const mapConfig = {
  //url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  url: 'https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png',
  attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> | Oulunliikenne',
  center: [ 65.01236, 25.46816 ],
  zoomLevel: 14,
  searchZoomLevel: 16,
  style: { height: '75vh' },
};

const WrappedReactLeafletSearch = () => {
  const searchControl = new GeoSearchControl({
    position: "topleft",
    style: 'button',
    provider: new OpenStreetMapProvider(),
    showMarker: false,
    showPopup: false,
    retainZoomLevel: true, //todo should zoom
    searchLabel: "Etsi osoite",
    autoclose: true,
    //zoomLevel: mapConfig.zoomLevel,
  });
  const map = useMap();
  useEffect(() => {
    map.addControl(searchControl);
    return () => map.removeControl(searchControl);
  }, []);

  return null;
}

const FormMap = ({ onChange, geoJson }) => {
  return (
    <MapContainer
      center={mapConfig.center}
      zoom={mapConfig.zoomLevel}
      style={mapConfig.style}
    >
      <TileLayer url={mapConfig.url} attribution={mapConfig.attribution}/>
      <FullscreenControl />
      
      <WrappedReactLeafletSearch />

      <MapDrawControls onChange={onChange} geoJson={geoJson} />
    </MapContainer>
  );
}

export default FormMap;
