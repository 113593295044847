import React from 'react';
import queryString from 'query-string';
import { AuthContext } from './AuthContext';

class Login extends React.Component {
  UNSAFE_componentWillMount() {
    this.checkLogin();
  }

  checkLogin = () => {
    const values = queryString.parse(this.props.location.hash);

    if (values.access_token && this.context) {
      this.context.setAccessToken(values.access_token, () => {
        this.props.history.push('/');
      });
    }
  }

  render() {
    return null;
  }
}

Login.contextType = AuthContext;

export default Login;
