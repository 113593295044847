import React from 'react';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/fi'
import { AuthProvider, AuthConsumer } from './AuthContext';
import AppRouter from './AppRouter';
import * as serviceWorker from './serviceWorker';
import './index.scss';
import { createRoot } from 'react-dom/client';

const App = () => (
  <AuthProvider>
    <AuthConsumer>
      {({ token }) => {
        return (
        <ApolloProvider client={new ApolloClient({
          uri: process.env.REACT_APP_GRAPHQL_URL,
          headers: {
            Authorization: token,
          },
          cache: new InMemoryCache({
            dataIdFromObject: o => o.announcementId
          }),
        })}>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='fi'>
            <AppRouter />
          </LocalizationProvider>
        </ApolloProvider>
      )}}
    </AuthConsumer>
  </AuthProvider>
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
