import React, { Component, createRef } from 'react';
import { Query } from '@apollo/client/react/components';
import { graphql } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash'
import { withRouter } from "react-router";

import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from 'react-material-ui-form-validator';
import { DateTimePicker, renderTimeViewClock } from '@mui/x-date-pickers';
import {
  Button,
  Checkbox,
  CircularProgress,
  Fab, FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import get from 'lodash/get';
import find from 'lodash/find';
import merge from 'lodash/merge';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import omitDeep from 'omit-deep-lodash';
import omitEmpty from 'omit-empty';
import moment from 'moment';
import { toast } from 'react-toastify';

import { GET_ITEM, GET_LIST } from './queries';
import {
  CREATE_ITEM,
  UPDATE_ITEM,
  DELETE_ITEM,
  PUBLISH_ITEM,
  UNPUBLISH_ITEM,
  UPLOAD_IMAGE
} from './mutations';
import { formConfig } from './config';
import FormMap from './FormMap';
import FormCheckboxGroupValidator from './FormCheckboxGroupValidator';
import './Create.scss';
import Spinner from './Spinner';
import Error from './Error';

// Fix (hack) for collapsing form items in Chrome.
const CustomGrid = ({children, ...rest}) => {
  const style = {};

  if (rest.item) {
    style.width = '100%';
  }
  
  return (
    <Grid {...rest} style={style}>{children}</Grid>
  );
}

class CreateContainer extends Component {
  render() {
    const id = get(this, 'props.match.params.id');

    return id ? (
      <Query query={GET_ITEM} variables={{ id }}>
        {({ loading, error, data }) => {
          if (loading) {
            return <Spinner message={'Haetaan tiedotetta'} />
          }
          if (error) {
            return <Error message={'Tiedotteen hakemisessa on tilapäisesti ongelmia'} />
          }

          return (
            <CreateWithMutations item={data.trafficAnnouncement} />
          );
        }}
      </Query>
    ) : (
      <CreateWithMutations />
    );
  }
}

class Create extends Component {
  //This looks just dirty, but is required for validation purposes...
  formRef = createRef();
  modesOfTransportRef = createRef();
  mainClassRef = createRef();
  subClassRef = createRef();
  titleFiRef = createRef();
  descFiRef = createRef();
  geojsonRef = createRef();
  severityRef = createRef();
  trafficDirFreeTextRef = createRef();
  startTimeRef = createRef();
  state = {
    modesOfTransport: '',
    class: [
      {
        class: '',
        subclass: '',
      },
    ],
    title: {
      fi: '',
      sv: '',
      en: '',
    },
    startTime: '',
    endTime: '',
    description: {
      fi: '',
      sv: '',
      en: '',
    },
    images: '',
    url: '',
    geojson: '',
    trafficDirectionFreeText: {
      fi: '',
      sv: '',
      en: '',
    },
    duration: '',
    temporarySpeedLimit: '',
    additionalInfo: '',
    severity: '',
    detour: '',
    oversizeLoad: '',
    vehicleSizeLimit: '',

     // FRONTEND ONLY
    detourEnabled: false,
    uploadingImage: false,
    isNewAnnouncement: true,
    geojsonError: false,
    startTimeError: false,
    startTimeOpen: false,
    endTimeOpen: false,
  };

  componentDidMount() {
    const { item } = this.props;

    if (item) {
      const updatedState = merge(this.state, omitEmpty(item));

      if (updatedState.detour) {
        updatedState.detourEnabled = true;
      }

      if (updatedState.announcementId) {
        updatedState.isNewAnnouncement = false;
      }

      this.setState(updatedState);
    }
  }

  handleAddClass = () => {
    const classList = this.state.class;
    classList.push({ class: '', subclass: '' });
    this.setState({ class: classList });
  }

  handleDeleteClass = (key) => {
    const classList = this.state.class;
    classList.splice(key, 1);
    this.setState({ class: classList });
  }

  handleUploadImage = (evt) => {
    if (evt.target.files && evt.target.files[0]) {
      const fileItem = evt.target.files[0];
      const reader = new FileReader(fileItem);
      reader.readAsDataURL(fileItem);
      reader.onload = () => {
        const title = {
          fi: '',
          sv: '',
          en: '',
        };
        const file = {
          contentType: fileItem.type,
          fileName: fileItem.name,
          file: reader.result.split(',')[1],
        };

        this.setState({ uploadingImage: true });

        this.props.uploadImage(title, file)
          .then(({ data: { uploadTrafficAnnouncementImage } }) => {
            const images = Array.isArray(this.state.images) ? this.state.images : [];
            this.setState({
              images: [...images, uploadTrafficAnnouncementImage],
              uploadingImage: false,
            });
          }).catch((error) => {
            toast.error('Kuvan latauksessa tapahtui virhe');
            this.setState({ uploadingImage: false });
          });
      };
      reader.onerror = error => {
        toast.error('Tiedostoa ei voitu avata');
      };
    }
  }

  handleClearImage = index => {
    const { images } = this.state;
    this.setState({
      images: Array.isArray(images) && images.length ? images.filter((image, imageIndex) => imageIndex !== index) : ''
    });
  }

  handleChange = (evt, subproperty, itemKey) => {
    const value = evt.target.value;

    if (subproperty !== undefined && typeof subproperty === 'string') {
      const itemName = evt.target.name.slice(0, -(subproperty.length + 1))
      const item = this.state[itemName];

      if (itemKey !== undefined) {
        item[itemKey][subproperty] = value;

        if (
          evt.target.name === `${formConfig.mainClass.name}_${formConfig.mainClass.subproperty}` &&
          !find(formConfig.subClass[item[itemKey][formConfig.mainClass.subproperty]], { value: item[itemKey][formConfig.subClass.subproperty] })
        ) {
          item[itemKey][formConfig.subClass.subproperty] = formConfig.subClass.items[0].value;
        }
      } else {
        item[subproperty] = value;
      }

      this.setState({ [evt.target.name]: item });
    } else {
      this.setState({ [evt.target.name]: value });
    }
  }

  handleDateChange = (date, name) => {
    this.setState({ [name]: date ? date.format('YYYY-MM-DDTHH:mm:ss') : date });
  }

  handleDatePickerOpen = (name, open) => {
    this.setState({ [name]: open})
  }

  handleCheckboxChange = evt => {
    this.setState({ [evt.target.name]: evt.target.checked });
  }

  handleMultiCheckboxChange = evt => {
    let values = this.state[evt.target.name] || [];

    if (evt.target.checked) {
      values.push(evt.target.value);
    } else {
      values = values.filter(value => value !== evt.target.value);
    }

    this.setState({ [evt.target.name]: values });
  }

  handleMapOnChange = (name, data) => {
    data.features = uniqWith(data.features, isEqual);
    this.setState({ [name]: data });
  }

  handleDelete = evt => {
    evt.preventDefault();

    const { title } = this.state;

    if (window.confirm(`Haluatko varmasti poistaa tiedotteen "${title.fi || ''}"?`)) {
      this.props.deleteTrafficAnnouncement({ id: this.state.announcementId })
        .then(({ data }) => {
          toast.success('Tiedote poistettu');
          this.props.history.push(`/list`);
        }).catch((error) => {
          toast.error('Tiedotteen poistamisessa tapahtui virhe');
        });
    }
  }

  handlePublish = evt => {
    evt.preventDefault();

    this.props.publishTrafficAnnouncement({ id: this.state.announcementId })
      .then(({ data }) => {
        this.setState({ publishedVersion: data.publishTrafficAnnouncement.publishedVersion });
        toast.success('Tiedote julkaistu');
      }).catch((error) => {
        toast.error('Tiedotteen julkaisemisessa tapahtui virhe');
      });
  }

  handleUnpublish = evt => {
    evt.preventDefault();

    this.props.unpublishTrafficAnnouncement({ id: this.state.announcementId })
      .then(({ data }) => {
        this.setState({ publishedVersion: data.unpublishTrafficAnnouncement.publishedVersion });
        toast.success('Tiedotteen julkaisu poistettu');
      }).catch((error) => {
        toast.error('Tiedotteen julkaisun poistamisessa tapahtui virhe');
      });
  }

  handleSubmit = evt => {
    evt.preventDefault();

    // Geojson-field validation
    if (this.handleError()) {
      return null;
    }

    const item = omitDeep(
      {
        title: this.state.title,
        description: this.state.description,
        severity: this.state.severity,
        startTime: this.state.startTime,
        endTime: this.state.endTime || null,
        geojson: this.state.geojson || null,
        modesOfTransport: this.state.modesOfTransport,
        class: this.state.class,
        trafficDirectionFreeText: this.state.trafficDirectionFreeText,
        temporarySpeedLimit: this.state.temporarySpeedLimit || null,
        duration: this.state.duration || null,
        additionalInfo: this.state.additionalInfo || null,
        detour: this.state.detourEnabled && this.isGeojsonSet(this.state.detour) ? this.state.detour : null,
        oversizeLoad: this.state.oversizeLoad,
        vehicleSizeLimit: this.state.vehicleSizeLimit,
        url: this.state.url,
        images: Array.isArray(this.state.images) ? this.state.images.map(image => {
          return {
              type: 'Link',
              linkType: 'Asset',
              id: image.imageId,
            };
        }) : null,
      },
      ['__typename']);

    if (this.state.announcementId) {
      item.id = this.state.announcementId;
    }

    if (item.id) {
      this.props.updateTrafficAnnouncement(item)
        .then(({ data: { updateTrafficAnnouncement } }) => {
          if (updateTrafficAnnouncement.publishedVersion) {
            this.props.publishTrafficAnnouncement({ id: updateTrafficAnnouncement.announcementId })
              .then(({ data: { publishTrafficAnnouncement } }) => {
                this.setState({ publishedVersion: publishTrafficAnnouncement.publishedVersion });
                toast.success('Tiedote päivitetty :)');
              }).catch((error) => {
                toast.error('Tiedotteen päivitetty mutta julkaisemisessa tapahtui virhe');
              });
          } else {
            toast.success('Tiedote päivitetty');
          }
        }).catch((error) => {
          toast.error('Tiedotteen päivityksessä tapahtui virhe');
        });
    } else {
      this.props.createTrafficAnnouncement(item)
        .then(({ data }) => {
          toast.success('Tiedote luotu');
          this.props.history.push(`/update/${data.createTrafficAnnouncement.announcementId}`);
        }).catch((error) => {
          toast.error('Tiedotteen luomisessa tapahtui virhe');
        });
    }
  }
  
  resolveFieldNameToRef = fieldName => {
    if(fieldName === "modesOfTransport") return this.modesOfTransportRef;
    else if(fieldName === "class_class") return this.mainClassRef;
    else if(fieldName === "class_subclass") return this.subClassRef;
    else if(fieldName === "title_fi") return this.titleFiRef;
    else if(fieldName === "description_fi") return this.descFiRef;
    else if(fieldName === "severity") return this.severityRef;
    else if(fieldName === "trafficDirectionFreeText_fi") return this.trafficDirFreeTextRef;
    return null;
  }

  handleError = errorComponents => {
    let firstDomNode = null
    if(Array.isArray(errorComponents) && errorComponents.length) {
      const errorRefs = errorComponents.map(field => 
        this.resolveFieldNameToRef(field?.props?.name)
      ).filter(Boolean);
      firstDomNode = document.getElementById(errorRefs[0].current.props.id);
    }


    if (!this.isGeojsonSet(this.state.geojson)) {
      this.setState({ geojsonError: true });
      const geojsonDomNode = this.geojsonRef;

      if (!firstDomNode || geojsonDomNode.current.offsetTop < firstDomNode.offsetTop) {
        firstDomNode = geojsonDomNode.current;
      }
    }

    if (!this.state.startTime) {
      this.setState({ startTimeError: true });
      const startTimeDomNode = this.startTimeRef;

      if (!firstDomNode || startTimeDomNode.current.offsetTop < firstDomNode.offsetTop) {
        firstDomNode = startTimeDomNode.current;
      }
    }

    if (firstDomNode) {
      toast.warning('Täytä kaikki vaadittavat kentät');
      firstDomNode.scrollIntoView({behavior: 'auto', block: 'center', inline: 'center'})
      return true;
    }

    return false;
  }

  isGeojsonSet = (geojson) => {
    return (geojson && get(geojson, 'features', []).length);
  }

  clearErrorCustom = name => {
    this.setState({ [name]: false });
  }

  handleBlur = event => {
    const fieldRef = this.resolveFieldNameToRef(event.target.name)
    if(!fieldRef) return
    fieldRef.current.validate(event.target.value)
  }

  clearError = name => {
    const fieldRef = this.resolveFieldNameToRef(name);
    if(!fieldRef.current.isValid()){
      fieldRef.current.makeValid();
    }
  }

  handleKeyPress = evt => {
    // Prevent submitting form with Enter-key
    if (evt.key === 'Enter') {
      evt.preventDefault();
    }
  }

  render() {
    const {
      modesOfTransport,
      mainClass,
      subClass,
      title,
      startTime,
      endTime,
      description,
      images,
      url,
      geojson,
      trafficDirectionFreeText,
      duration,
      temporarySpeedLimit,
      additionalInfo,
      severity,
      detourEnabled,
      oversizeLoad,
      vehicleSizeLimit,
    } = formConfig;

    return (
      <div className="create">
        <h1>{this.state.isNewAnnouncement ? 'Uusi tiedote' : 'Päivitä tiedote'}</h1>

        <p className="published-status">{(!this.state.isNewAnnouncement && this.state.publishedVersion) ? 'JULKAISTU' : 'EI JULKAISTU'}</p>

        <ValidatorForm
          ref={this.formRef}
          onSubmit={this.handleSubmit}
          onError={this.handleError}
          autoComplete="off"
          noValidate
          instantValidate={false}
        >
          <CustomGrid container spacing={5} direction="column">
            <CustomGrid item xs={12}>
              <FormCheckboxGroupValidator
                id={modesOfTransport.name}
                name={modesOfTransport.name}
                ref={this.modesOfTransportRef}
                validators={['required']}
                errorMessages={['Valitse vähintään yksi']}
                onCheckboxChange={this.handleMultiCheckboxChange}
                value={this.state[modesOfTransport.name]}
                options={modesOfTransport}
              />
            </CustomGrid>
            <CustomGrid item xs={12}>
              {(this.state[mainClass.name] || []).map((item, key) => (
                <CustomGrid container spacing={5} direction="row" alignItems="center" key={key}>
                  <CustomGrid item xs={5}>
                    <SelectValidator
                      id={`${mainClass.name}_${mainClass.subproperty}`}
                      name={`${mainClass.name}_${mainClass.subproperty}`}
                      ref={this.mainClassRef}
                      label={mainClass.label}
                      value={item[mainClass.subproperty]}
                      onChange={evt => this.handleChange(evt, mainClass.subproperty, key)}
                      required
                      onBlur={() => this.handleBlur({target: this.mainClassRef.current.props})}
                      onFocus={() => this.clearError(`${mainClass.name}_${mainClass.subproperty}`)}
                      validators={['required']}
                      errorMessages={['Vaadittu kenttä']}
                      fullWidth
                    >
                      {mainClass.items.map((item, key) =>
                        <MenuItem value={item.value} key={key} disabled={item.disabled || false}>{item.name}</MenuItem>
                      )}
                    </SelectValidator>
                  </CustomGrid>
                  <CustomGrid item xs={5}>
                    <SelectValidator
                      id={`${subClass.name}_${subClass.subproperty}`}
                      name={`${subClass.name}_${subClass.subproperty}`}
                      ref={this.subClassRef}
                      label={subClass.label}
                      value={item[subClass.subproperty]}
                      onChange={evt => this.handleChange(evt, subClass.subproperty, key)}
                      required
                      onBlur={() => this.handleBlur({target: this.subClassRef.current.props})}
                      onFocus={() => this.clearError(`${subClass.name}_${subClass.subproperty}`)}
                      validators={['required']}
                      errorMessages={['Vaadittu kenttä']}
                      fullWidth
                    >
                      {subClass.items.concat((subClass[item[mainClass.subproperty]] || [])).map((item, key) =>
                        <MenuItem value={item.value} key={key} disabled={item.disabled || false}>{item.name}</MenuItem>
                      )}
                    </SelectValidator>
                  </CustomGrid>
                  <CustomGrid item xs={2}>
                    {this.state[mainClass.name].length > 1 && (
                      <Fab
                        size="small"
                        color="secondary"
                        aria-label="Delete"
                        onClick={() => this.handleDeleteClass(key)}
                        style={{ marginRight: '10px' }}
                      >
                        <DeleteIcon />
                      </Fab>
                    )}
                    {key+1 === this.state[mainClass.name].length && (
                      <Fab
                        size="small"
                        color="primary"
                        aria-label="Add"
                        onClick={this.handleAddClass}
                      >
                        <AddIcon />
                      </Fab>
                    )}
                  </CustomGrid>
                </CustomGrid>
              ))}
            </CustomGrid>
            <CustomGrid item xs={10}>
              <TextValidator
                id={`${title.fi.name}_${title.fi.subproperty}`}
                name={`${title.fi.name}_${title.fi.subproperty}`}
                label={title.fi.label}
                value={this.state[title.fi.name][title.fi.subproperty]}
                onChange={evt => this.handleChange(evt, title.fi.subproperty)}
                onKeyPress={this.handleKeyPress}
                fullWidth
                ref={this.titleFiRef}
                required
                onBlur={this.handleBlur}
                onFocus={(event) => this.clearError(event.target.name)}
                validators={['required']}
                errorMessages={['Vaadittu kenttä']}
              />
            </CustomGrid>
            <CustomGrid item xs={10}>
              <TextField
                id={`${title.sv.name}_${title.sv.subproperty}`}
                name={`${title.sv.name}_${title.sv.subproperty}`}
                label={title.sv.label}
                value={this.state[title.sv.name][title.sv.subproperty]}
                onChange={evt => this.handleChange(evt, title.sv.subproperty)}
                onKeyPress={this.handleKeyPress}
                fullWidth
              />
            </CustomGrid>
            <CustomGrid item xs={10}>
              <TextField
                id={`${title.en.name}_${title.en.subproperty}`}
                name={`${title.en.name}_${title.en.subproperty}`}
                label={title.en.label}
                value={this.state[title.en.name][title.en.subproperty]}
                onChange={evt => this.handleChange(evt, title.en.subproperty)}
                onKeyPress={this.handleKeyPress}
                fullWidth
              />
            </CustomGrid>
            <CustomGrid item xs={4}>
              <DateTimePicker
                id={startTime.name}
                ref={this.startTimeRef}
                name={startTime.name}
                open={this.state.startTimeOpen}
                onOpen={() => this.handleDatePickerOpen("startTimeOpen", true)}
                onClose={() => this.handleDatePickerOpen("startTimeOpen", false)}
                label={startTime.label}
                value={this.state[startTime.name] ? moment(this.state[startTime.name]) : null}
                onChange={date => {
                  this.handleDateChange(date, startTime.name);
                  if(this.state.startTimeError){
                    this.clearErrorCustom('startTimeError');
                  }
                }}
                format="DD/MM/YYYY, hh:mm"
                viewRenderers={{
                  hours: renderTimeViewClock, minutes: renderTimeViewClock
                }}
                autoOk
                ampm={false}
                fullWidth
                slotProps={{
                  textField: {
                    required: true,
                    error: this.state.startTimeError,
                    helperText: this.state.startTimeError
                      ? "Vaadittu kenttä" 
                      : "" 
                  }
                }}
              />
            </CustomGrid>
            <CustomGrid item xs={4}>
              <DateTimePicker
                id={endTime.name}
                name={endTime.name}
                open={this.state.endTimeOpen}
                onOpen={() => this.handleDatePickerOpen("endTimeOpen", true)}
                onClose={() => this.handleDatePickerOpen("endTimeOpen", false)}
                label={endTime.label}
                value={this.state[endTime.name] ? moment(this.state[endTime.name]) : null}
                onChange={date => this.handleDateChange(date, endTime.name)}
                format="DD/MM/YYYY, hh:mm"
                viewRenderers={{
                  hours: renderTimeViewClock, minutes: renderTimeViewClock
                }}
                autoOk
                ampm={false}
                fullWidth
                slotProps={{ actionBar: { actions: ["clear", "accept"] } }}
              />
            </CustomGrid>
            <CustomGrid item xs={10}>
              <TextValidator
                id={`${description.fi.name}_${description.fi.subproperty}`}
                name={`${description.fi.name}_${description.fi.subproperty}`}
                label={description.fi.label}
                value={this.state[description.fi.name][description.fi.subproperty]}
                onChange={evt => this.handleChange(evt, description.fi.subproperty)}
                fullWidth
                multiline
                minRows={5}
                variant="outlined"
                ref={this.descFiRef}
                required
                onBlur={this.handleBlur}
                onFocus={(event) => this.clearError(event.target.name)}
                validators={['required']}
                errorMessages={['Vaadittu kenttä']}
              />
            </CustomGrid>
            <CustomGrid item xs={10}>
              <TextField
                id={`${description.sv.name}_${description.sv.subproperty}`}
                name={`${description.sv.name}_${description.sv.subproperty}`}
                label={description.sv.label}
                value={this.state[description.sv.name][description.sv.subproperty]}
                onChange={evt => this.handleChange(evt, description.sv.subproperty)}
                fullWidth
                multiline
                minRows={5}
                variant="outlined"
              />
            </CustomGrid>
            <CustomGrid item xs={10}>
              <TextField
                id={`${description.en.name}_${description.en.subproperty}`}
                name={`${description.en.name}_${description.en.subproperty}`}
                label={description.en.label}
                value={this.state[description.en.name][description.en.subproperty]}
                onChange={evt => this.handleChange(evt, description.en.subproperty)}
                fullWidth
                multiline
                minRows={5}
                variant="outlined"
              />
            </CustomGrid>
            {this.state.uploadingImage && (
              <CustomGrid item xs={10}>
                <CircularProgress color="primary"/>
              </CustomGrid>
            )}
            {!this.state.uploadingImage && (
              <CustomGrid item xs={10}>
                <InputLabel className="label-margin" shrink>
                  {images.label}
                </InputLabel>
                <Input
                  id={images.name}
                  name={images.name}
                  // value={this.state[images.name]}
                  type="file"
                  accept="image/*"
                  onChange={this.handleUploadImage}
                  disableUnderline={true}
                />
              </CustomGrid>
            )}
            {Array.isArray(this.state[images.name]) && (
              <CustomGrid item xs={10}>
                {this.state[images.name].map((image, index) => (
                  <div key={`image_${index}`}>
                    <img
                      src={image.url} alt={image.fileName}
                      style={{ maxWidth: '80%' }}
                    />
                    <Fab
                      size="small"
                      color="secondary"
                      aria-label="Delete"
                      onClick={() => this.handleClearImage(index)}
                      style={{ marginLeft: '10px' }}
                    >
                      <DeleteIcon />
                    </Fab>
                  </div>
                ))}
              </CustomGrid>
            )}
            <CustomGrid item xs={10}>
              <TextField
                id={url.name}
                name={url.name}
                label={url.label}
                value={this.state[url.name]}
                placeholder={url.placeholder}
                onChange={this.handleChange}
                onKeyPress={this.handleKeyPress}
                fullWidth
              />
            </CustomGrid>
            <CustomGrid item xs={12}>
              <InputLabel
                id={geojson.name}
                ref={this.geojsonRef}
                error={this.state.geojsonError}
                className="label-margin"
                shrink
                required
              >
                {geojson.label}
              </InputLabel>
              <FormMap
                geoJson={this.state.geojson}
                onChange={(data) => {
                  this.handleMapOnChange('geojson', data)
                  if(this.state.geojsonError){
                    this.clearErrorCustom('geojsonError')
                  }
                }}
              />
            </CustomGrid>
            <CustomGrid item xs={5}>
              <Typography variant="h6">
                Lisätietoja
              </Typography>
            </CustomGrid>
            <CustomGrid item xs={5}>
              <SelectValidator
                id={severity.name}
                name={severity.name}
                ref={this.severityRef}
                label={severity.label}
                value={this.state[severity.name]}
                onChange={this.handleChange}
                required
                onBlur={() => this.handleBlur({target: this.severityRef.current.props})}
                onFocus={() => this.clearError(severity.name)}
                validators={['required']}
                errorMessages={['Vaadittu kenttä']}
                fullWidth
              >
                {severity.items.map((item, key) =>
                  <MenuItem value={item.value} key={key} disabled={item.disabled || false}>{item.name}</MenuItem>
                )}
              </SelectValidator>
            </CustomGrid>
            <CustomGrid item xs={10}>
              <TextValidator
                id={`${trafficDirectionFreeText.fi.name}_${trafficDirectionFreeText.fi.subproperty}`}
                name={`${trafficDirectionFreeText.fi.name}_${trafficDirectionFreeText.fi.subproperty}`}
                label={trafficDirectionFreeText.fi.label}
                value={this.state[trafficDirectionFreeText.fi.name][trafficDirectionFreeText.fi.subproperty]}
                onChange={evt => this.handleChange(evt, trafficDirectionFreeText.fi.subproperty)}
                onKeyPress={this.handleKeyPress}
                fullWidth
                ref={this.trafficDirFreeTextRef}
                onBlur={this.handleBlur}
                onFocus={(event) => this.clearError(event.target.name)}
              />
            </CustomGrid>
            <CustomGrid item xs={10}>
              <TextField
                id={`${trafficDirectionFreeText.sv.name}_${trafficDirectionFreeText.sv.subproperty}`}
                name={`${trafficDirectionFreeText.sv.name}_${trafficDirectionFreeText.sv.subproperty}`}
                label={trafficDirectionFreeText.sv.label}
                value={this.state[trafficDirectionFreeText.sv.name][trafficDirectionFreeText.sv.subproperty]}
                onChange={evt => this.handleChange(evt, trafficDirectionFreeText.sv.subproperty)}
                onKeyPress={this.handleKeyPress}
                fullWidth
              />
            </CustomGrid>
            <CustomGrid item xs={10}>
              <TextField
                id={`${trafficDirectionFreeText.en.name}_${trafficDirectionFreeText.en.subproperty}`}
                name={`${trafficDirectionFreeText.en.name}_${trafficDirectionFreeText.en.subproperty}`}
                label={trafficDirectionFreeText.en.label}
                value={this.state[trafficDirectionFreeText.en.name][trafficDirectionFreeText.en.subproperty]}
                onChange={evt => this.handleChange(evt, trafficDirectionFreeText.en.subproperty)}
                onKeyPress={this.handleKeyPress}
                fullWidth
              />
            </CustomGrid>
            <CustomGrid item xs={5}>
              <SelectItem
                name={duration.name}
                label={duration.label}
                items={duration.items}
                value={this.state[duration.name]}
                onChange={this.handleChange}
              />
            </CustomGrid>
            <CustomGrid item xs={5}>
              <SelectItem
                name={temporarySpeedLimit.name}
                label={temporarySpeedLimit.label}
                items={temporarySpeedLimit.items}
                value={this.state[temporarySpeedLimit.name]}
                onChange={this.handleChange}
              />
            </CustomGrid>
            <CustomGrid item xs={5}>
              <SelectItem
                name={additionalInfo.name}
                label={additionalInfo.label}
                items={additionalInfo.items}
                value={this.state[additionalInfo.name]}
                onChange={this.handleChange}
              />
            </CustomGrid>
            <CustomGrid item xs={5}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={detourEnabled.name}
                    checked={this.state[detourEnabled.name]}
                    onChange={this.handleCheckboxChange}
                    value='1'
                  />
                }
                label={detourEnabled.label}
              />
            </CustomGrid>
            {this.state[detourEnabled.name] && (
              <CustomGrid item xs={12}>
                <FormMap geoJson={this.state.detour} onChange={(data) => {this.handleMapOnChange('detour', data)}} />
              </CustomGrid>
            )}
            <CustomGrid item xs={8}>
              <TextField
                id={oversizeLoad.name}
                name={oversizeLoad.name}
                label={oversizeLoad.label}
                value={this.state[oversizeLoad.name]}
                helperText={oversizeLoad.placeholder}
                onChange={this.handleChange}
                onKeyPress={this.handleKeyPress}
                fullWidth
              />
            </CustomGrid>
            <CustomGrid item xs={8}>
              <TextField
                id={vehicleSizeLimit.name}
                name={vehicleSizeLimit.name}
                label={vehicleSizeLimit.label}
                value={this.state[vehicleSizeLimit.name]}
                helperText={vehicleSizeLimit.placeholder}
                onChange={this.handleChange}
                onKeyPress={this.handleKeyPress}
                fullWidth
              />
            </CustomGrid>
            <CustomGrid item xs={12}>
              <CustomGrid container spacing={8} direction="row" alignItems="center">
                <CustomGrid item xs={2}>
                  <Button variant="contained" color="primary" type="submit">
                    {this.state.isNewAnnouncement ? 'Luo tiedote' : 'Päivitä tiedote'}
                  </Button>
                </CustomGrid>
                {!this.state.isNewAnnouncement && !this.state.publishedVersion && (
                  <CustomGrid item xs={2}>
                    <Button variant="contained" color="secondary" onClick={this.handleDelete}>
                      Poista tiedote
                    </Button>
                  </CustomGrid>
                )}
                {!this.state.isNewAnnouncement && !this.state.publishedVersion && (
                  <CustomGrid item xs={2}>
                    <Button variant="contained" color="primary" onClick={this.handlePublish}>
                      Julkaise
                    </Button>
                  </CustomGrid>
                )}
                {this.state.publishedVersion && (
                  <CustomGrid item xs={2}>
                    <Button variant="contained" color="primary" onClick={this.handleUnpublish}>
                      Poista julkaisu
                    </Button>
                  </CustomGrid>
                )}
              </CustomGrid>
            </CustomGrid>
          </CustomGrid>
        </ValidatorForm>
      </div>
    );
  }
}

const CreateWithRouter = withRouter(Create);

const CreateWithMutations = compose(
  graphql(UPLOAD_IMAGE, {
    name: 'uploadImageMutation',
    props: ({ uploadImageMutation }) => ({
      uploadImage: (title, file) => uploadImageMutation({ variables: { title, file } }),
    }),
  }),
  graphql(CREATE_ITEM, {
    name: 'createTrafficAnnouncementMutation',
    props: ({ createTrafficAnnouncementMutation }) => ({
      createTrafficAnnouncement: (variables) => createTrafficAnnouncementMutation({ variables }),
    }),
    options: {
      refetchQueries: () => [{query: GET_LIST, variables: { fetchAssets: false }}],
      // update: (cache, { data: { createTrafficAnnouncement } }) => {
      //   try {
      //     const { trafficAnnouncements } = cache.readQuery({ query: GET_LIST });
      //     cache.writeQuery({
      //       query: GET_LIST,
      //       data: { trafficAnnouncements: [ ...trafficAnnouncements, createTrafficAnnouncement ] },
      //     });
      //   }
      //   catch(error) {
      //     console.error(error);
      //   }
      // },
    },
  }),
  graphql(UPDATE_ITEM, {
    name: 'updateTrafficAnnouncementMutation',
    props: ({ updateTrafficAnnouncementMutation }) => ({
      updateTrafficAnnouncement: (variables) => updateTrafficAnnouncementMutation({ variables }),
    }),
  }),
  graphql(DELETE_ITEM, {
    name: 'deleteTrafficAnnouncementMutation',
    props: ({ deleteTrafficAnnouncementMutation }) => ({
      deleteTrafficAnnouncement: (variables) => deleteTrafficAnnouncementMutation({ variables }),
    }),
    options: {
      refetchQueries: () => [{query: GET_LIST, variables: { fetchAssets: false }}],
    },
  }),
  graphql(PUBLISH_ITEM, {
    name: 'publishTrafficAnnouncementMutation',
    props: ({ publishTrafficAnnouncementMutation }) => ({
      publishTrafficAnnouncement: (variables) => publishTrafficAnnouncementMutation({ variables }),
    }),
  }),
  graphql(UNPUBLISH_ITEM, {
    name: 'unpublishTrafficAnnouncementMutation',
    props: ({ unpublishTrafficAnnouncementMutation }) => ({
      unpublishTrafficAnnouncement: (variables) => unpublishTrafficAnnouncementMutation({ variables }),
    }),
  }),
)(CreateWithRouter);

const SelectItem = (props) => {
  const { name, label, items, ...selectProps } = props;

  return (
    <FormControl fullWidth>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Select
        {...selectProps}
        inputProps={{
          name: name,
          id: name,
        }}
      >
        {items.map((item, key) =>
          <MenuItem value={item.value} key={key}>{item.name}</MenuItem>
        )}
      </Select>
    </FormControl>
  );
}

export default CreateContainer;
