import { gql } from "@apollo/client";

export const CREATE_ITEM = gql`
  mutation CreateTrafficAnnouncement(
    $title: LocalizedStringInput!
    $description: LocalizedStringInput!
    $severity: TrafficAnnouncementSeverity!
    $startTime: GraphQLDateTime!
    $endTime: GraphQLDateTime
    $geojson: FeatureCollectionObject!
    $modesOfTransport: [TrafficAnnouncementModeOfTransport]!
    $class: [TrafficAnnouncementClassInput]!
    $trafficDirectionFreeText: LocalizedStringInput
    $temporarySpeedLimit: Int
    $duration: String
    $additionalInfo: String
    $detour: FeatureCollectionObject
    $oversizeLoad: String
    $vehicleSizeLimit: String
    $url: String
    $images: [TrafficAnnouncementImageLinkInput]
  ) {
    createTrafficAnnouncement(
      title: $title
      description: $description
      severity: $severity
      startTime: $startTime
      endTime: $endTime
      geojson: $geojson
      modesOfTransport: $modesOfTransport
      class: $class
      trafficDirectionFreeText: $trafficDirectionFreeText
      temporarySpeedLimit: $temporarySpeedLimit
      duration: $duration
      additionalInfo: $additionalInfo
      detour: $detour
      oversizeLoad: $oversizeLoad
      vehicleSizeLimit: $vehicleSizeLimit
      url: $url
      images: $images
    ) {
      announcementId
      publishedVersion
      modesOfTransport
      class {
        class
        subclass
      }
      title {
        fi
        sv
        en
      }
      startTime
      endTime
      description {
        fi
        sv
        en
      }
      images {
        id
        imageId
        contentType
        fileName
        url
      }
      url
      geojson
      trafficDirectionFreeText {
        fi
        sv
        en
      }
      duration
      temporarySpeedLimit
      additionalInfo
      severity
      detour
      oversizeLoad
      vehicleSizeLimit
    }
  }
`;

export const UPDATE_ITEM = gql`
  mutation UpdateTrafficAnnouncement(
    $id: ID!
    $title: LocalizedStringInput
    $description: LocalizedStringInput
    $severity: TrafficAnnouncementSeverity
    $startTime: GraphQLDateTime
    $endTime: GraphQLDateTime
    $geojson: FeatureCollectionObject
    $modesOfTransport: [TrafficAnnouncementModeOfTransport]
    $class: [TrafficAnnouncementClassInput]
    $trafficDirectionFreeText: LocalizedStringInput
    $temporarySpeedLimit: Int
    $duration: String
    $additionalInfo: String
    $detour: FeatureCollectionObject
    $oversizeLoad: String
    $vehicleSizeLimit: String
    $url: String
    $images: [TrafficAnnouncementImageLinkInput]
  ) {
    updateTrafficAnnouncement(
      id: $id
      title: $title
      description: $description
      severity: $severity
      startTime: $startTime
      endTime: $endTime
      geojson: $geojson
      modesOfTransport: $modesOfTransport
      class: $class
      trafficDirectionFreeText: $trafficDirectionFreeText
      temporarySpeedLimit: $temporarySpeedLimit
      duration: $duration
      additionalInfo: $additionalInfo
      detour: $detour
      oversizeLoad: $oversizeLoad
      vehicleSizeLimit: $vehicleSizeLimit
      url: $url
      images: $images
    ) {
      announcementId
      publishedVersion
      modesOfTransport
      class {
        class
        subclass
      }
      title {
        fi
        sv
        en
      }
      startTime
      endTime
      description {
        fi
        sv
        en
      }
      images {
        id
        imageId
        contentType
        fileName
        url
      }
      url
      geojson
      trafficDirectionFreeText {
        fi
        sv
        en
      }
      duration
      temporarySpeedLimit
      additionalInfo
      severity
      detour
      oversizeLoad
      vehicleSizeLimit
    }
  }
`;

export const DELETE_ITEM = gql`
  mutation RemoveTrafficAnnouncement(
    $id: ID!
  ) {
    removeTrafficAnnouncement(
      id: $id
    )
  }
`;

export const PUBLISH_ITEM = gql`
  mutation PublishTrafficAnnouncement(
    $id: ID!
  ) {
    publishTrafficAnnouncement(
      id: $id
    ) {
      announcementId
      publishedVersion
    }
  }
`;

export const UNPUBLISH_ITEM = gql`
  mutation UnpublishTrafficAnnouncement(
    $id: ID!
  ) {
    unpublishTrafficAnnouncement(
      id: $id
    ) {
      announcementId
      publishedVersion
    }
  }
`;

export const UPLOAD_IMAGE = gql`
  mutation UploadTrafficAnnouncementImage(
    $title: LocalizedStringInput!
    $file: TrafficAnnouncementImageInput!
  ) {
    uploadTrafficAnnouncementImage(
      title: $title
      file: $file
    ) {
      id
      imageId
      contentType
      fileName
      url
    }
  }
`;
